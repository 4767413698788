<template>
  <div style="background-color: #00A8BB">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div id="app">
      <ul id="menu"  style="background-color:#000">
      </ul>
      <v-container fluid grid-list-md fill-height style="padding:3%;">
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 style="text-align: center">
            <div class="direita">
                <a href="http://enercred.com.br" target="_blank" alt="Enercred - Energia Limpa e Barata"><img src="../../assets/logo-enercred-branco.png" width="300px" style="padding: 5px" alt="Enercred - Energia Limpa e Barata" class="src"></a>
                <div class="header">
                    <span class="font-weight-black" >Olá, {{ name }}!</span>
                    <div style="color:#FFF">Está preparado(a) para ter uma economia de aproximadamente R${{ economia }} por ano?<br>
                    Precisamos agora que leia atentamente o contrato a seguir antes de entrar em nossa lista de pré-adesão.<br><br></div>
                </div>
                <div>
                    <v-textarea
                        box
                        background-color="#FFF"
                        height="430px"
                        name="precontrato"
                        :value="text"
                        readonly
                    ></v-textarea>
                </div>
                <v-checkbox v-model="aceito" color="white"><template v-slot:label><div style="color:#FFF">Li e concordo com os termos do contrato de pré-adesão acima.</div></template></v-checkbox>
                <v-layout align-center justify-center>
                    <v-btn :disabled="!aceito" dark color="rgb(164, 196, 37)" @click="aceitar()">Aceitar</v-btn>
                </v-layout>
                
            </div>
          </v-flex>
          <v-flex d-flex xs12 sm12 md6 style="padding-top: 10%; vertical-align: bottom; vertical-align: text-bottom;">
            <p><img src="../../assets/fundo.png" width="110%"></p>
            <!--
            <div class="header">
                <b>Aproveite e baixe nosso aplicativo</b><br>
            </div>
            -->
          </v-flex>
          <v-snackbar
            :color="color"
            :bottom="bottom"
            :top="top"
            :left="left"
            :right="right"
            v-model="snackbar"
            dark
          >
            <v-icon
              color="white"
              class="mr-3"
            >
              mdi-bell-plus
            </v-icon>
            <div>{{snackMessage}}</div>
            <v-icon
              size="16"
              @click="snackbar = true"
            >
              mdi-close-circle
            </v-icon>
          </v-snackbar>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
  //import { addLead } from '../../services/leads'
  //import { getIpAddress } from '../../services/geobytes'
//   import precontrato from './precontrato.txt'

  export default {
    data() {
        return {
            valid: false,
            components: {
                FileReader
            },
            // text: precontrato?precontrato:'',
            text: '',
            email: this.$route.query.email,
            name: this.$route.query.name,
            economia: this.$route.query.economia,
            aceito: false,
            color: null,
            colors: [
                'purple',
                'info',
                'success',
                'warning',
                'error'
            ],
            top: true,
            bottom: false,
            left: false,
            right: false,
            snackMessage: '',
            snackbar: false,
        };
    },
    mounted() {

    },
    methods: {
        snack (message, color, ...args) {
            this.top = false
            this.bottom = false
            this.left = false
            this.right = false

            for (const loc of args) {
                this[loc] = true
            }

            this.color = color  
            this.snackMessage = message
            this.snackbar = true
        },
        aceitar: function(){
            if(!this.email) this.snack('E-mail inválido ou não existente. Favor reabrir página pelo link enviado ou contatar empresa.','error','top');
            else {
                var dateTime = new Date();
                let user = {
                    email: this.email,
                    name: this.name,
                    economia: this.economia,
                    data: dateTime,
                }
                localStorage.setItem('user', JSON.stringify(user));
                //this.$router.replace('')
                
                /*
                getIpAddress()
                    .then((result) => {
                        var dateTime = new Date();
                        let user = {
                            email: this.email,
                            name: this.name,
                            economia: this.economia,
                            data: dateTime,
                            remoteIp: result,
                        }
                        localStorage.setItem('user', JSON.stringify(user));
                        // console.log(user);
                        //this.$router.replace('')
                    })
                */
            }
        },
        /*signUp: function() {
            let user = {
                customer_id: this.email,
                email: this.email,
                name: this.name,
                state: this.state,
                phone: this.phone,
                promo_code: this.promo_code,
                average_consumption: this.average_consumption,
                step: this.step
            }
            
            addLead(user)
                    .then((result) => {
                        if(result.data) {
                            localStorage.setItem('lead', JSON.stringify(user))
                            this.$router.replace('cadastro-conta-energia')
                        }
                    })
                    .catch(err => {
                        console.dir('Error: ', err);
                    })
        }*/
    }
  }
</script>

<style scoped>
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,
form,fieldset,input,textarea,p,blockquote,th,td {
    padding: 0;
    margin: 0;
}
a{
    text-decoration:none;
}

fieldset,img {
    border: 0;
}
ol,ul {
    list-style: none;
    margin:0;
    padding:0;
}
caption,th {
    text-align: left;

}
h2,h3,h4,h5,h6 {
    font-weight: normal;
    font-size: 100%;
    margin:0;
    padding:0;
}
q:before,q:after {
    content:'';
}
abbr,acronym { border: 0;
}
*{
    -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box; /* <=28 */
    box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,body {
    font-family: arial, helvetica;
    height:100%;
}
.area {
    height: 100px;
    width: 100px;
    background: red;
    margin:10px;
    text-align: center;
    display:table-cell;
    vertical-align:middle;
}
.content {
    height: 100px;
    width: 100px;
    display:table-cell;
    vertical-align:middle;    
}
.header {
    font-size: 2em;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    display: table-cell;
    color: #FFF;
}
.header span {
  font-size: 2em;
  color: #FFF;
  font-weight: bold;
}
/*
.header br {
  font-size: 4em;
  color: #FFF;
  font-weight: bold;
}
*/
/*
.formulario {
    height: 600px;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    background-color:#FFF;
    padding: 10px 50px 10px 50px;
    text-align: center;
    border-radius: 10px;
}
*/
.direita {
    width:95%;
    padding-left: 5%;
    text-align: left;
}
.section{
    position: relative;
    text-align: center;
}
#section-1 h2{
    color: #fff;
    font-size: 10em;
    font-weight: 900;
}
#section-1 h1{
    font-size: 2em;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    margin: 1.5em auto 1em auto;
    color: #35495e;
    padding-right: 30px;
    padding-left: 30px;
}
#section-1 li {
    display: inline-block;
    margin: 1.25em 0.3em;
}
.section-1-button{
    padding: 0.93em 1.87em;
    background: #35495e;
    border-radius: 5px;
    display: block;
    color: #fff;
}
#section-1 h2{
    color: #fff;
    font-size: 10em;
    font-weight: 500;
}
#section-3 h1{
    font-size: 2em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    margin: 1.5em auto 1em auto;
    color: #35495e;
    padding-right: 30px;
    padding-left: 30px;
}
#section-3 li {
    display: inline-block;
    margin: 1.25em 0.3em;
}
.section-3-button{
    padding: 0.93em 1.87em;
    background: #35495e;
    border-radius: 5px;
    display: block;
    color: #fff;
}
#section-3 .intro {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: 50%;
    padding: 0 3em;
}

h3{
    font-size: 4em;
    /* text-align: center; */
    color: #fff;
    font-weight: bold;
}
h2{
    font-size: 2em;
    /* text-align: center; */
    color: #fff;
    font-weight: bold;
}
#logo{
    position: fixed;
    top: 20px;
    left: 20px;
    color: #fff;
    font-weight: bold;
    z-index: 99;
    font-size: 1.9em;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}



/* Menu
 * --------------------------------------- */
#menu-line {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 159px;
    height: 2px;
    background: #fff;
}

#menu{
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 70;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    letter-spacing: 1px;
    font-size: 1.1em;
}
#menu li{
    display: inline-block;
    margin: 10px 0px;
    position: relative;
}
#menu a{
    color: #fff;
    padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after{
    content: '';
    margin: 0 1.1em 0 1.1em;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions{
    position: fixed;
    bottom: 2%;
    margin: 0 auto;
    z-index: 99;
    left: 0;
    right: 0;
    text-align: center;
}
.actions li{
    display: inline-block;
    margin: 0.3em 0.3em;
}
.actions-button{
    padding: 0.73em 1.47em;
    background: rgba(53, 73, 94, 0.47);
    border-radius: 5px;
    display: block;
    color: #fff;
    cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i{
    vertical-align: middle;
    position: relative;
    top: 2px;
    display: inline-block;
    width: 38px;
    height: 14px;
    color: white;
    top: -4px;
    left: -2px;
    fill: #fff;
}
.twitter-share svg{
    height: 40px;
    margin-top: -10px;
}
.view-github{
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 999;
    padding: .93em 1.87em;
    background: #35495e;
    border-radius: 5px;
    display: block;
    color: #fff;
}


.shell {
    width: calc(50% - 0px);
    display: inline-block;
    background-color: #FFF;
    /*  background-image: url('../assets/eolica.png'); */
    background-position:center;
    border-radius: 0px 0px 0 0;
    text-align: left;
    margin-right: 0px;
}

.signUp {
        margin-top: 60px;
    }
    input {
        margin: 10px 0;
        width: 20%;
        padding: 15px;
    }
    button {
        margin-top: 10px;
        width: 10%;
        cursor: pointer;
    }
    span {
        display: block;
        margin-top: 20px;
        font-size: 11px;
    }
    .form_title {
        font-size: 2em;
        color: rgb(99, 97, 97);
        font-weight: bold;
    }

</style>

